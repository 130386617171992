import axios from 'axios'
import { AxiosResponse, AxiosError } from 'axios';
import { app } from '../config/config';
import { TloginUserPayload,
  TuserProfileResponse,
  TsimpleResponse,
  TresetPasswordEmailResponse,
  TsignUpRegister,
  TgoogleAuthReq } from './userApiTypes'


export function getUserProfile() {
  const axiosOptions = {
    method: 'GET',
    withCredentials: true,
    url: `${app.BACKEND_URL}/auth/myaccount`
  };

  return new Promise<AxiosResponse<TuserProfileResponse>>((resolve, reject) => {
    axios(axiosOptions)
    .then(function (response) {
      // handle success
      resolve(response);
    })
    .catch(function (error) {
      // handle error
      console.log(error)
      reject('No possible to authenticate')
    })
  })
}

export function loginUser(props: TloginUserPayload) {
  const { username, password } = props;

  const options = {
    method: 'POST',
    withCredentials: true,
    data: JSON.stringify({
      username,
      password 
    }),
    url: `${app.BACKEND_URL}/auth/login`
  };

  return new Promise<AxiosResponse<TuserProfileResponse>>((resolve, reject) => {
    axios(options)
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error) {
      console.log(error)
      reject(error);
    })
  })
}

export function logOut() {
  const axiosOptions = {
    method: 'GET',
    withCredentials: true,
    url: `${app.BACKEND_URL}/auth/logout`
  };

  return new Promise<AxiosResponse<TsimpleResponse>>((resolve, reject) => {
    axios(axiosOptions)
    .then(function (response) {
      // handle success
      resolve(response);
    })
    .catch(function (error) {
      // handle error
      console.log(error)
      reject('No possible to logout')
    })
  })
}


interface resetPassword {
  type: string,
  username?: string,
  verificationCode?: string,
  newPassword?: string,
}

export function resetPasswordFlow(payload: resetPassword) {

  const options = {
    method: 'POST',
    data: JSON.stringify({
      ...payload,
    }),
    url: `${app.BACKEND_URL}/auth/signup`
  };

  return new Promise<AxiosResponse<TresetPasswordEmailResponse>>((resolve, reject) => {
    axios(options)
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error) {
      console.log(error)
      reject(error);
    })
  })
}

interface resetPassword {
  type: string,
  phone_number?: string,
  email?: string,
  password?: string,
}

export function signUpRegister(payload: resetPassword) {

  const options = {
    method: 'POST',
    data: JSON.stringify({
      ...payload,
    }),
    url: `${app.BACKEND_URL}/auth/signup`
  };

  return new Promise<AxiosResponse<TsignUpRegister>>((resolve, reject) => {
    axios(options)
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error) {
      console.log(error)
      reject(error);
    })
  })
}

export function googleAuth(props: TgoogleAuthReq) {
  const options = {
    method: 'POST',
    withCredentials: true,
    data: JSON.stringify({
      type: 'google',
      ...props,
    }),
    url: `${app.BACKEND_URL}/auth/google`
  };

  return new Promise<AxiosResponse<TuserProfileResponse>>((resolve, reject) => {
    axios(options)
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error) {
      console.log(error)
      reject(error);
    })
  })
}