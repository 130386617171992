import React, { useState, useRef, useLayoutEffect, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import useConfig from '../../../hooks/useConfig'
import styled from 'styled-components'
import { TsignUpStep } from './SignUp'
import Cookies from 'universal-cookie'
import { AxiosError } from 'axios'
import { signUpRegister } from '../../../services/auth/api'

import loadingGif from '../../../assets/gifs/loading.gif'
import { TresponseError } from '../../../services/auth/userApiTypes'

const cities = ['Santa Cruz de la Sierra', 'Cochabamba', 'La Paz']

const cookies = new Cookies()

interface message {
  type: 'info' | 'error' | 'none',
  message: string | undefined,
}

interface Tregister { changeRegisterStep: (step: TsignUpStep) => void }
export default function Register(props: Tregister) {
  const config = useConfig()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [ message, setMessage ] = useState<message>({ type: 'none', message: '' })

  const [ enableSignUp, setEnableSignUp ] = useState<boolean>(false);
  const [ loading, setLoading ] = useState<boolean>(false);

  const usernameInput = useRef<HTMLInputElement>(null)
  const emailInput = useRef<HTMLInputElement>(null)
  const passInput = useRef<HTMLInputElement>(null)
  const phoneInput = useRef<HTMLInputElement>(null)
  const countryCodeInput = useRef<HTMLInputElement>(null)
  const nombreInput = useRef<HTMLInputElement>(null)
  const apellidosInput = useRef<HTMLInputElement>(null)
  const citySelect = useRef<HTMLSelectElement>(null)


  function inputChange() {
    if (emailInput.current &&
        usernameInput.current &&
        passInput.current &&
        phoneInput.current &&
        nombreInput.current &&
        apellidosInput.current &&
        citySelect.current) {

      if (emailInput.current.value !== '' && 
          usernameInput.current.value !== '' && 
          passInput.current.value !== '' && 
          phoneInput.current.value !== '' &&
          nombreInput.current.value !== '' && 
          apellidosInput.current.value !== '' &&
          citySelect.current.value !== '')  {
        setEnableSignUp(true);
        console.log(emailInput.current.value)
        console.log(passInput.current.value)
        console.log(phoneInput.current.value)
        console.log(nombreInput.current.value)
        console.log(apellidosInput.current.value)
        console.log(citySelect.current.value)
      } else {
        setEnableSignUp(false);
      }
    }
  }
  
  function registrarse(event: React.SyntheticEvent) {
    setMessage({ type: 'none', message: '' });
    setLoading(true);
    event.preventDefault();

    const target = event.target as typeof event.target & {
      phone: { value: string };
      country_code: { value: string };
      email: { value: string };
      password: { value: string };
      username: { value: string };
      given_name: { value: string };
      family_name: { value: string };
      city: { value: string };
    };
  
    const payload = {
      type: "signup",
      username: target.username.value,
      phone_number: `${target.country_code.value} ${target.phone.value}`,
      email: target.email.value,
      password: target.password.value,
      given_name: target.given_name.value,
      family_name: target.family_name.value,
      city: target.city.value,
    }

    console.log(payload)

    signUpRegister(payload)
    .then(resp => {
      console.log('Register: ', resp)
      setLoading(false);
      cookies.set(config.app.COOKIE_SIGNUP, target.username.value);
      props.changeRegisterStep('verificacion')
    })
    .catch(err => {
      let error: AxiosError<TresponseError> = err
      setLoading(false)
      setMessage({ type: 'error', message: error.response?.data.message })
      console.log('ResetPassNewPass Error: ', err)
    })
  }

  return (
    <FormContainer>

      <Title>REGISTRO</Title>

      <Form onSubmit={registrarse} >

        <Input
          type="text"
          ref={usernameInput}
          onChange={() => { inputChange() }}
          placeholder="Apodo"
          name="username"
        />

        <Input
          type="text"
          ref={nombreInput}
          onChange={() => { inputChange() }}
          placeholder="Nombres"
          name="given_name"
        />

        <Input
          type="text"
          ref={apellidosInput}
          onChange={() => { inputChange() }}
          placeholder="Apellidos"
          name="family_name"
        />

        <Input
          type="email"
          ref={emailInput}
          onChange={() => { inputChange() }}
          placeholder="Email"
          name="email"
        />

        <Input
          type="password"
          ref={passInput}
          onChange={() => { inputChange() }}

          placeholder="Contraseña"
          name="password"
        />

        <PhoneInputContainer>
          <CountryCodeInput
            type='text'
            placeholder='+591'
            disabled={true}
            name="country_code"
            value='+591'
            ref={countryCodeInput}
          />
          
          <PhoneInput
            type="tel"
            ref={phoneInput}
            onChange={() => { inputChange() }}
            placeholder="Telefono"
            name="phone"
          />
        </PhoneInputContainer>

        <SelectCity
          id="fast-input-marca"
          name="city"
          onChange={inputChange}
          ref={citySelect}
        >
          <option value="" disabled selected>Seleccione una ciudad</option>

          {
            cities.map((city, index) => (
              <option value={city} key={`marca${index}`}>
                { city }
              </option>
            ))
          }

        </SelectCity>

        <p className={ message.type === 'error' ? 'auth-messageError' : 'auth-hidden' } >
            { message.message }
        </p>

        <SignUpButton
          type="submit"
          ready={enableSignUp}
          disabled={!enableSignUp || loading ? true : false}
        >
          { loading ? '' : 'Continuar' }
          <LoadingGif isLoading={loading} src={loadingGif}/>
        </SignUpButton>

      </Form>

      <GoToLogin>
        Ya tienes una cuenta?
        
          <HighLighted
            onClick={() => {
              props.changeRegisterStep('login')
            }}
          >
            Inicia Sesión
          </HighLighted>
        
      </GoToLogin>
        
    </FormContainer>
  )
}

const FormContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 300px;
`
const Title = styled.p`
  font-weight: 800;
  font-size:  25px;
  color: #201E1F;
  font-family: Montserrat;
  margin-bottom: 10px;
  text-align: center;
  max-width: 250px;
  margin: 0px auto 10px auto;
  margin: 10px auto;
  font-family: Roboto;
`

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`

interface TsignUpButton { ready: boolean }
const SignUpButton = styled.button<TsignUpButton>`
  width: 90%;
  background-color: #50b2c055;
  
  border-radius: 10px;
  font-size: Montserrat;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 0px 10px 0px;
  color: white;
  border: none;
  text-decoration: none;
  margin-top: 40px;
  ${(args) => args.ready && `
    background-color: #50b2c0;
  `}
`
  interface TloadingGif { isLoading: boolean }
  const LoadingGif = styled.img<TloadingGif>`
    width: 23px;
    display: none;
    ${(args) => args.isLoading && `
      display: initial;
    `}
  `

interface Tinput { error: boolean }
const Input = styled.input`
  width: 90%;
  border: 1px solid #f7efe7;
  border-radius: 5px;
  //border: 1px solid rgba(211,211,211, 0.5);
  font-size: 13px;
  line-height: 30px;
  margin: 15px 0px 0px 0px;
  padding: 5px 10px 5px 10px;
  
  &:focus {
    outline: 1px solid black;
  }
`
const SelectCity = styled.select`
  width: 290px;
  border: none;
  border-radius: 5px;
  background-color: white;
  font-size: 13px;
  line-height: 30px;
  margin: 15px 0px 0px 0px;
  padding: 10px 5px 10px 15px;
`
const PhoneInputContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 290px;;
  padding: 0px 10px 0px 10px;
  gap: 10px;
`
  const CountryCodeInput = styled.input`
    flex-grow: 1;
    width: 50px;
    border: none;
    border-radius: 5px;
    background-color: white;
    font-size: 13px;
    line-height: 30px;
    margin: 15px 0px 0px 0px;
    padding: 5px 5px 5px 15px;
  `
  const PhoneInput = styled(Input)`
    width: auto;
    flex-grow: 4;
  `

const GoToLogin = styled.p`
  align-self: flex-end;
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
  cursor: pointer;
  text-align: right;
  text-decoration: none !important;
  color: #312C38;

`
const HighLighted = styled.span`
  color: #50B2C0;
  font-weight: 600;
  margin-left: 5px;
`