import React from 'react'
import styled from 'styled-components'

import main_logo from '../../assets/icons/ui/main-logo.png'
import { useNavigate } from 'react-router-dom'

export default function About() {
  const navigate = useNavigate()

  return(
    <Container>
      <Side>
        <Logo src={main_logo} />

        <Text>
          Pluriapp, es una pagina web que beneficiara a los productores y artesanos de comunidades rurales (indígenas-campesinos) manteniendo la identidad y el legado cultural ancestral de sus productos a través de un turismo productivo, gastronómico y cultural, que apoyara a la visualización, comercialización de sus productos locales y que generaran una reactivación económica en el sector. Además Plurirapp actuaría como nexo con el turista que quiere comprar el producto al artesano o productor, que podrá contactarse con la operadora de turismo para realizar una visita vivencial exclusiva al  artesano – productor
        </Text>
      </Side>

      <Button
        onClick={() => {
          navigate(-1)
        }}
      >
        Volver
      </Button>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  gap: 10px;
  align-items: center;
  min-height: calc(100vh - 80px);
  width: 100%;
  padding-top: 80px;
`

const Side = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  margin: 0px auto 0px auto;
  width: 100%;
  @media(max-width: 950px) {
    flex-flow: column nowrap;
    flex-direction: column-reverse;
    gap: 10px;
  }
`

  const Text = styled.p`
    font-family: Nunito, sans-serif;
    font-size: 16px;
    text-align: justify;
    width: 350px;
    @media(max-width: 950px) {
      flex-flow: column nowrap;
      font-size: 14px;
      width: 300px;
    }
  `

  const Logo = styled.img`
    object-fit: cover;
    height: 250px;
    @media(max-width: 950px) {
      height: 100px;
    }
  `

const Button = styled.button`
  border: none;
  font-family: Alegreya Sanas, sans-serif;
  font-weight: 600;
  font-size: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 50px;
  background-color: #2cccc4;
  color: white;
  cursor: pointer;
  @media(max-width: 950px) {
    margin-top: 20px;
  }
`