import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {  RouterProvider } from 'react-router-dom'
import reportWebVitals from './reportWebVitals';
import './font-family.css'

import ConfigContext from './context/ConfigContext'
import config from './config/config'

import { Provider } from 'react-redux'
import store from './app/store'

import ViewportProvider from './context/viewportContext'
import { GoogleOAuthProvider } from '@react-oauth/google'

import router from './app/routes'


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider
      clientId="539739879397-002b6q6rdp01dfk7e2qhjc1onum5laai.apps.googleusercontent.com"
    >
      <ConfigContext.Provider value={config}>
        <ViewportProvider>
          <Provider store={store}>
              <RouterProvider router={router} />
          </Provider>
          </ViewportProvider>
      </ConfigContext.Provider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
