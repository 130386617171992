import { configureStore, Action } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/dist/query"
import { ThunkAction } from "@reduxjs/toolkit"

import user from "../services/auth/userSlice"
import appUi from "./AppUISlice"

import { TypedUseSelectorHook, useSelector, useDispatch } from "react-redux"

const store = configureStore({
  reducer: {
    user,
    appUi,
  }
})

export type TrootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, TrootState, null, Action<string>>

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<TrootState> = useSelector

setupListeners(store.dispatch)

export default store