import React, { useEffect, useState, useLayoutEffect } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import useViewport from "../../hooks/useViewport";

import { products } from "../../assets/mocks/products";

import urus_background from '../../assets/images/urus/urus_main.jpg'
import urus_background_sd from '../../assets/images/urus/urus_main_sd.jpg'
import urus_background_mob from '../../assets/images/urus/urus_main_mob.jpg'
import { TrootState, useAppSelector } from "../../app/store";
import useConfig from "../../hooks/useConfig";

import loadingGif from '../../assets/gifs/loading.gif'

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}


interface Titem {
  id: string,
  title: string,
  mainImage: string,
  images: string[],
  comunidad: string,
  precio: number,
  desc: string,
}

export default function Craft() {
  const { app } = useConfig()
  const params = useParams()
  const navigate = useNavigate()
  const { bodyWidth, bodyTop } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [isSD, setIsSD] = useState(false)
  const [ item, setItem ] = useState<Titem|undefined>(undefined)
  const [ loading, setLoading ] = useState(false)

  const user = useAppSelector((state: TrootState) => state.user)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])


  useEffect(() => {
    if (params.craft) {
      const item = products.find((item) => item.id === params.craft)
      if (item) setItem(item as Titem)
    }
  }, [])

  const checkout = () => {
    const checkOutForm: any = document.getElementById("pluriapp-checkout-craft");
    if (checkOutForm) {
      checkOutForm.submit();
    }
  };


  return(
    <Container>

      { !isMobile &&
        <SideComunidad>
          <TextComunidad>
            <TitleComunidad> Nación Urus </TitleComunidad>
            <Paragrah>
              Según relatan nuestros abuelos los grandes sabios andantes, nuestra cultura se extendia desde Puno, el lago Titicaca, Chucuito, Wiñay Marca, el río desaguadero, Lago Uru Uru, lago Poopó y salar de Coipasa, estaba casi en todos los alrededores de los lagos mencionados y el río Desaguadero, hasta las costas del océano y una parte de Argentina, eso dicen nuestros abuelos.
            </Paragrah>

            <Autor>
              (Lorenzo Inda - sabio Uru Iruhito)
            </Autor>
          </TextComunidad>
        </SideComunidad>
      }

      { item &&
        <Side>          
          <CardCraft>
            { !isMobile && 
              <ItemImg src={item.mainImage}/>
            }

            <Title>
              { item.title }
            </Title>

            { !isMobile &&
              <Comprar>

                <ComprarButton
                  disabled={loading}
                  onClick={() => {
                    if (!user.data) {
                      navigate('/login')
                    } else {
                      setLoading(true)
                      checkout()
                    }
                  }}
                >
                  { loading ?
                    <img src={loadingGif} style={{width: '30px'}}/>
                    :
                    'Comprar'
                  }
                </ComprarButton>

                <Precio>
                  { item.precio } $us
                </Precio>
              </Comprar>
            }

            <TextCraft>
              { item.desc }
            </TextCraft>

            { isMobile &&
              <Comprar>

                <ComprarButton
                  disabled={loading}
                  onClick={() => {
                    if (!user.data) {
                      navigate('/login')
                    } else {
                      setLoading(true)
                      checkout()
                      
                    }
                  }}
                >
                  { loading ?
                    <img src={loadingGif} style={{width: '30px'}}/>
                    :
                    'Comprar'
                  }

                </ComprarButton>

                <Precio>
                  { item.precio } $us
                </Precio>

              </Comprar>
            }

          </CardCraft>

          { isMobile && 
            <ItemImg src={item.mainImage}/>
          }
          
        </Side>
      }

      
    { item?.id &&
      <form 
        action={`${app.BACKEND_URL}/payments/checkout/${item.id}`}
        id="pluriapp-checkout-craft"
        method="POST"
      >
      </form>
    }

    </Container>
  )
}


const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: calc(100vh - 80px);
  padding-top: 80px;
  background-image: url(${urus_background});
  @media(max-width: 1470px) {
    background-image: url(${urus_background_sd});
  }
  @media(max-width: 950px) {
    background-image: url(${urus_background_mob});
    flex-flow: column nowrap;
    justify-content: center;
  }
`

const Side = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  min-width: 50%;
  @media(max-width: 950px) {
    gap: 5vh;
  }
`


const CardCraft = styled.div`
  background-color: #ffffff55;
  border-radius: 5px;
  padding: 20px 20px 20px 15px;
  max-width: 500px;
  @media (max-width: 1740px ) {
    max-width: 400px;
    padding: 10px 20px 10px 15px;
  }
  @media(max-width: 950px) {
    max-width: 310px;
    background-color: #00000022;
  }
  
`

const ItemImg = styled.img`
  max-height: 350px;
  max-width: 500px;
  margin: -4px 4px 4px -4px;
  padding: 4px;
  background-color: #fcfcfc;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  border-right: 1px solid #888;
  border-bottom: 1px solid #888;
  box-shadow: 5px 5px 5px #00000055;
  @media (max-width: 1740px ) {
    height: 200px;
  }
  @media (max-width: 950px ) {
    max-height: 250px;
    max-width: 300px;
  }
`

  const Title = styled.p`
    text-align: left;
    font-family: Nunito, sans-serif;
    font-size: 22px;
    font-weight: 600;
    margin-top: 2px;
    margin: 5px 0px;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: calc(100% - 5px);
    color: white;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);

    @media (max-width: 950px ) {

    }
  `

  const Precio = styled.p`
    font-family: Nunito, sans-serif;
    font-size: 32px;
    font-weight: 700;
    text-align: right;
    padding-right: 10px;
    color: white;
    margin: 5px 0px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
    @media (max-width: 1740px ) {
      font-size: 24px;
    }
    @media(max-width: 950px) {
      font-size: 24px;
    }
  `

  const TextCraft = styled.p`
    font-family: Nunito, sans-serif;
    font-size: 16px;
    background-color: #00000022;
    text-align: justify;
    color: white;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    padding: 5px 10px;
    border-radius: 5px;
    max-height: 250px;
    overflow-y: auto;
    @media (max-width: 1740px ) {
      font-size: 14px;
    }
    @media(max-width: 950px) {
      background-color: transparent;
      font-size: 13px;
      padding: 5px 0px;
    }
  `

const SideComunidad = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  min-width: 50%;  
`
  const TextComunidad = styled.div`
    display: flex;
    flex-flow: column nowrap;
    margin-left: 50px;
    background-color: #00000011;
    border-radius: 5px;
    width: 350px;
    padding: 10px 15px 10px 15px;
    align-self: center;
    @media (max-width: 950px) {
      margin: 0px auto 0px auto;
      align-self: flex-start;
      background-color: transparent;
      width: 100%;
      background: linear-gradient(to bottom, #00000055, transparent 50%);
    }
  `

  const TitleComunidad = styled.div`
    display: inline-block;
    font-family: Alegreya Sans, sans-serif;
    font-size: 35px;
    line-height: 10px;
    font-weight: 600;
    color: white;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    text-align: left;
    margin: 20px 0px;
    @media (max-width: 950px) {
      width: 310px;
      margin-left: auto;
      margin-right: auto;
    }
  `

  const Paragrah = styled.p`
    display: inline-block;
    font-family: Nunito, sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: white;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
    width: 350px;
    text-align: justify;
    @media (max-width: 950px) {
      width: 310px;
      margin-left: auto;
      margin-right: auto;
    }
  `
    const Autor = styled(Paragrah)`
      margin-top: 0px;
      font-style: italic;
      text-align: right;
    `


  const Comprar = styled.div`
    display: flex;
    justify-content: space-between;
  `

    const ComprarButton = styled.button`
      color: white;
      background-color: #2cccc4;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
      border: none;
      font-family: Nunito, sans-serif;
      font-weight: 600;
      border-radius: 10px;
      padding: 10px 20px;
      box-shadow: 5px 5px 5px #00000055;
      min-width: 95px;
      cursor: pointer;
    `