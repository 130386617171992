import React, { useContext, useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { TsignInStep } from './SignIn'
import { resetPasswordFlow } from '../../../services/auth/api'
import { TresponseError } from '../../../services/auth/userApiTypes'

import './SignIn.css'

import loadingGif from '../../../assets/gifs/loading.gif'
import { AxiosError } from 'axios'
import backIcon from '../../assets/icons/ui/back.png'


interface message {
  type: 'info' | 'error' | 'none',
  message: string | undefined,
}

type resetStep = 'setNewPass' | 'setEmail'
interface TresetPassword { changeStep: (step: TsignInStep) => void }
export default function ResetPassword(props: TresetPassword) {
  const { changeStep } = props
  const [ message, setMessage ] = useState<message>({ type: 'none', message: undefined });
  const [ resetStep, setResetStep ] = useState<resetStep>('setEmail');
  const [ loading, setLoading ] = useState<boolean>(false);
  const [ enableSubmit, setEnableSubmit ] = useState<boolean>(false)
  const [ passMatch, setPassMatch ] = useState<boolean>(false);

  const emailInput = useRef<HTMLInputElement>(null);
  const password1 = useRef<HTMLInputElement>(null);
  const password2 = useRef<HTMLInputElement>(null);

  function inputChange(event: React.ChangeEvent<HTMLInputElement>) {

    switch (event.target.name) {
      case 'email':
          console.log(event.target.value)
          if (event.target.value !== '') setEnableSubmit(true)
          else setEnableSubmit(false)
        break;
    }

    if (emailInput.current ) {
      setMessage({ type: 'none', message: '' })
    }
  }

  function checkPasswordMatch(event: React.SyntheticEvent) {
    const target = event.target as Element
    const { id } = target;

    if (password1.current && password2.current ) {
      if (id === 'auth-confirmPassword' && password1.current.value !== '') {
        if (password1.current.value === password2.current.value) {
          setPassMatch(true);
          setMessage({ type: 'none', message: '' })
        } else {
          setPassMatch(false);
          setMessage({ type: 'error', message: 'Passwords does not match' })
        }
      } else if (id === 'auth-newPassword' && password2.current.value !== '') {
        if (password1.current.value === password2.current.value) {
          setPassMatch(true);
          setMessage({ type: 'none', message: '' })
        } else {
          setPassMatch(false);
          setMessage({ type: 'error', message: 'Passwords does not match' })
        }
      } else {
        setMessage({ type: 'none', message: '' })
      }
    }
  }

  useEffect(() => {
    if (passMatch) setEnableSubmit(true)
    else setEnableSubmit(false)
  }, [passMatch])

  function resetPasswordSubmit(event: React.SyntheticEvent) {
    event.preventDefault()

    const target = event.target as typeof event.target & {
      email: { value: string };
      verificationCode: { value: string };
      newPassword: { value: string };
      confirmPassword: { value: string };
    };

    if (resetStep === 'setEmail') {
      setLoading(true)
      resetPasswordFlow({
        type: 'resetPassword',
        username: target.email.value,
      }).then(resp => {
        setLoading(false)
        console.log('ResetPassword: ', resp)
        setMessage({ type: 'info', message: `Code sent to ${resp.data.CodeDeliveryDetails.Destination}` })
        setResetStep('setNewPass')
        setEnableSubmit(false)

      }).catch(err => {
        let error: AxiosError<TresponseError> = err
        setLoading(false)
        setMessage({ type: 'error', message: error.response?.data.message })
        console.log('ResetPass Error: ', err)
      })
    }

    if (resetStep === 'setNewPass') {
      setLoading(true)
      resetPasswordFlow({
        type: 'confirmResetPassword',
        verificationCode: target.verificationCode.value,
        username: target.email.value,
        newPassword: target.newPassword.value,
      }).then(resp => {
        setLoading(false)
        console.log('ResetPassword: ', resp)
        changeStep('resetPasswordSuccess')

      }).catch(err => {
        let error: AxiosError<TresponseError> = err
        setLoading(false)
        setMessage({ type: 'error', message: error.response?.data.message })
        console.log('ResetPassNewPass Error: ', err)
      })
    }
  }

  return (
    <FormContainer onSubmit={resetPasswordSubmit} >

      <Title>Olvidaste la contraseña</Title>

      <SubTitle resetStep={resetStep === 'setEmail'}>
        Escribe tu email y te enviaremos un codigo de verificación
      </SubTitle>

      {/* <SubTitle resetStep={resetStep === 'setCode'}>
        Please enter your verification code
      </SubTitle>

      <SubTitle resetStep={resetStep === 'setCode'}>
        We have sent a verification code to your email
      </SubTitle> */}

      <SubTitle resetStep={resetStep === 'setNewPass'}>
        Por favor introduzca su código de verificación y su nueva contraseña
      </SubTitle>

      <Form onSubmit={resetPasswordFlow}>

        <Input
          type="text"
          ref={emailInput}
          onChange={inputChange}
          resetStep={resetStep === 'setEmail'}
          placeholder="Email"
          name="email"
        />

        <Input
          type="number"
          resetStep={resetStep === 'setNewPass'}
          placeholder="Codigo"
          name="verificationCode"
        />

        <Input
          ref={password1}
          onChange={(event) => {checkPasswordMatch(event)}}
          type="password"
          resetStep={resetStep === 'setNewPass'}
          id="auth-newPassword"
          name="newPassword"
          placeholder='Contraseña'
        />

        <Input
          ref={password2}
          onChange={(event) => {checkPasswordMatch(event)}}
          type="password"
          resetStep={resetStep==='setNewPass'}
          id="auth-confirmPassword"
          name="confirmPassword"
          placeholder='Confirmar contraseña'
        />

        
        <ResponseMessage type={message.type}>
          { message.message }
        </ResponseMessage>

        <SubmitButton
          type="submit"
          ready={enableSubmit}
          disabled={!enableSubmit || loading ? true : false}
        >
          { loading ? '' :  resetStep !== 'setNewPass' ? 'Continuar' : 'Cambiar Contraseña' }
          <LoadingGif isLoading={loading} src={loadingGif}/>
        </SubmitButton>

      </Form>

      <Back onClick={() => changeStep('login')}>
        {/* <BackIcon src={backIcon}/> */}
        <span> Volver </span>
      </Back>

    </FormContainer>
  );
};

const FormContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 300px;
`
const Title = styled.p`
  font-weight: 800;
  font-size:  25px;
  color: black;
  font-family: Roboto;
  max-width: 250px;
  text-align: center;
  margin: 10px auto 10px auto;
  text-transform: uppercase;
`
interface Subtitle { resetStep: boolean }
const SubTitle = styled.p<Subtitle>`
  display: ${(args) => args.resetStep ? 'fixed' : 'none' };
  font-family: Nunito;
  color: black;
  align-self: center;
  text-align: left;
  font-size:  13px;
  font-weight: 600;
  margin-bottom: 10px;
  padding: 0px 5px;
`
const Form = styled.form`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`

interface Tinput { resetStep: boolean }
const Input = styled.input<Tinput>`
  display: ${(args) => args.resetStep ? 'initial' : 'none' };
  width: 90%;
  border: none;
  border-radius: 5px;
  border: 1px solid rgba(211,211,211, 0.5);
  font-size: 13px;
  line-height: 30px;
  margin: 15px auto 0px auto;
  padding: 5px 5px 5px 15px;
  &:focus {
    outline: 1px solid black;
  }
`

interface TsubmitButton { ready: boolean }
const SubmitButton = styled.button<TsubmitButton>`
  width: 100%;
  background-color: #50b2c055;
  border-radius: 5px;
  font-size: Montserrat;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 0px 10px 0px;
  color: white;
  border: none;
  text-decoration: none;
  margin-top: 0px;
  ${(args) => args.ready && `
    background-color: #50b2c055;
  `}
`
  interface TloadingGif { isLoading: boolean }
  const LoadingGif = styled.img<TloadingGif>`
    width: 23px;
    display: none;
    ${(args) => args.isLoading && `
      display: initial;
    `}
  `

interface TresponseMessage { type: string }
const ResponseMessage = styled.p<TresponseMessage>`
  opacity: ${(args) => args.type !== 'none' ? 1 : 0 };
  height: 30px;
  align-self: flex-end;
  font-weight: 500;
  color: ${(args) => args.type === 'error' ? 'red' : 'black' };
  font-size: 12px;
  margin-top: 5px;
  text-align: right;
`
const Back = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-self: flex-start;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  color: #50b2c0;
  margin-top: 15px;
  & > span {
    margin-left: 5px;
  }
`
const BackIcon = styled.img`
  height: 12px;
`