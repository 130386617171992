import React, { useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import useViewport from '../../../hooks/useViewport'
import { Carousel } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'


import urusBackground from '../../../assets/images/urus/urus_main.jpg'
import urusBackgroundSD from '../../../assets/images/urus/urus_main_sd.jpg'
import urusBackgroundMob from '../../../assets/images/urus/urus_main_mob.jpg'

import urus_01 from '../../../assets/images/urus/urus_some_01.jpg'
import urus_02 from '../../../assets/images/urus/urus_some_02.jpg'
import urus_03 from '../../../assets/images/urus/urus_some_03.jpg'
import urus_04 from '../../../assets/images/urus/urus_some_04.jpg'
import urus_05 from '../../../assets/images/urus/urus_some_05.jpg'
import urus_06 from '../../../assets/images/urus/urus_some_06.jpg'



const fotos = [
  urus_01, urus_02, urus_03, urus_04, urus_05, urus_06 
]

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

export default function UrusHome() {
  const navigate = useNavigate()
  const { bodyWidth, bodyTop } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [isSD, setIsSD] = useState(false)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  return(
    <Container>
      <Text>
        <Title> Nación Urus </Title>
        <Paragrah>
          Los urus según relatan nuestros abuelos, tíos y tías, como Kot sun, como gente del agua, hombre del agua, nosotros somos una cultura milenaria muy antigua. Hemos sido pescadores, cazadores, constructores de balsas, hacíamos nuestras herramientas nosotros mismos, tumi kako, asokana, llica para pescar y para cazar, nuestros abuelos eran constructores de balsa, conocían la totora, la flora, la fauna, la biodiversidad. Conocían tambien la naturaleza, tenían una relación, hablaban con la Madre Tierra, con el lago, con las constelaciones, bien reían los Urus...
        </Paragrah>
        <Paragrah>
          Según relatan nuestros abuelos los grandes sabios andantes, nuestra cultura se extendia desde Puno, el lago Titicaca, Chucuito, Wiñay Marca, el río desaguadero, Lago Uru Uru, lago Poopó y salar de Coipasa, estaba casi en todos los alrededores de los lagos mencionados y el río Desaguadero, hasta las costas del océano y una parte de Argentina, eso dicen nuestros abuelos.
        </Paragrah>

        <Autor>
          (Lorenzo Inda - sabio Uru Iruhito)
        </Autor>
      </Text>

      <Side>
        <FotoContainer>
          <FotoSlide src={urus_01}/>
        </FotoContainer>
        {/* <StyledCarousel>
          { fotos.map((item) => (
            <Carousel.Item>
              <FotoContainer>
                <FotoSlide src={item}/>
              </FotoContainer>

            </Carousel.Item>
          ))}
        </StyledCarousel> */}
        <VerArtesanias
          onClick={() => {
            navigate('/marketplace')
          }}
        >
            Ver Artesanias
        </VerArtesanias>
      </Side>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  min-height: 100vh;
  background-image: url(${urusBackground});
  background-repeat: no-repeat;
  background-size: cover;
  @media(max-width: 1470px) {
    background-image: url(${urusBackgroundSD});
  }
  @media(max-width: 950px) {
    background-image: url(${urusBackgroundMob});
    flex-flow: column nowrap;
  }
`

const Text = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-left: 50px;
  background-color: #00000055;
  border-radius: 5px;
  width: 400px;
  padding: 40px 15px 10px 15px;
  align-self: center;
  
  @media (max-width: 950px) {
    width: 350px;
    margin: 0px auto 0px auto;
    align-self: flex-start;
    background-color: transparent;
    width: 100%;
    background: linear-gradient(to bottom, #00000055, transparent 50%);
  }
`
  const Title = styled.p`
    display: inline-block;
    font-family: Alegreya Sans, sans-serif;
    font-size: 35px;
    line-height: 0px;
    font-weight: 600;
    color: white;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    text-align: left;
    margin-bottom: 20px;
    @media (max-width: 950px) {
      width: 310px;
      margin-left: auto;
      margin-right: auto;
    }
  `
  const Paragrah = styled.p`
    display: inline-block;
    font-family: Nunito, sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: white;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    width: 350px;
    text-align: justify;
    @media (max-width: 950px) {
      width: 310px;
      font-size: 12px;
      margin-left: auto;
      margin-right: auto;
    }
  `
  const Autor = styled(Paragrah)`
    margin-top: 0px;
    font-style: italic;
    text-align: right;
  `

const Side = styled.div`
  align-self: center;
`
  const FotoSlide = styled.img` 
    height: 500px;
    object-fit: cover;
    @media (max-width: 1570px) {
      
      height: 400px;
    }
    @media (max-width: 950px) {
      
      height: 200px;
    }
  `
  const FotoContainer = styled.div`
    object-fit: cover;
    overflow: hidden;
    width: 700px;
    height: 500px;
    @media (max-width: 1570px) {
      width: 600px;
      height: 400px;
    }
    @media (max-width: 950px) {
      width: 300px;
      height: 200px;
    }
  `
  const StyledCarousel = styled(Carousel)`
    width: 700px;
    height: 500px;
    border-radius: 3px;
    @media (max-width: 1570px) {
      width: 600px;
      height: 400px;
    }
    @media (max-width: 950px) {
      width: 300px;
      height: 200px;
    }
  `

  const VerArtesanias = styled.button`
    border: none;
    font-family: Nunito, sans-serif;
    font-weight: 600;
    background-color: yellow;
    padding: 10px 20px;
    margin: 10px auto;
    border-radius: 5px;
  `