import React, { useState } from 'react'
import styled from 'styled-components'
import { TrootState, useAppDispatch, useAppSelector } from '../../app/store'
import { closeSession } from '../../services/auth/userSlice'
import { useNavigate } from 'react-router-dom'


import AuthComponent from '../../components/auth/auth'
import userDefault from '../../assets/icons/ui/user.png'

import loadingGif from '../../assets/gifs/loading.gif'


export default function AuthView() {  
  const dispatch  = useAppDispatch()
  const navigate = useNavigate()
  const [ loading, setLoading ] = useState(false)


  const user = useAppSelector((state: TrootState) => state.user)

  function logOut() {
    dispatch(closeSession())
    .then((result: any) => {
      setLoading(false)
      if (result.meta.requestStatus === 'fulfilled') {
      
        navigate('/')
      }
    })
    .catch((err) => {
      setLoading(false)
    })
  }

  

  return(
    <Container>

      <TitleView> Mi Cuenta </TitleView>

      { !user.data && <AuthComponent/> }

      {
        user.data &&
        <>
          <UserCard>

            <UserPic>
              <Picture src={ user.data?.profile_image ? user.data.profile_image : userDefault} />
            </UserPic>

            <UserInfo>
              {/* <Title> { user.data?. } { user.data.family_name }</Title> */}

              <Subtitle>
                { user.data ? user.data.email : 'loading' }
              </Subtitle>

              <CloseSession
                
                onClick={() => {
                  setLoading(true)
                  logOut()
                }}
              >
                { loading ?
                  <img src={loadingGif} style={{width: '30px'}}/>
                  :
                  'Cerrar Sesión'
                }
              </CloseSession>
            </UserInfo>

          </UserCard>
        </>
      }

      <Button
        onClick={() => {
          navigate(-1)
        }}
      >
        Volver
      </Button>

    </Container>
  )
}

const Container = styled.div`
  margin-top: 65px;
  margin-bottom: 80px;
  height: calc(100vh - 145px);
  display: flex;
  flex-flow: column nowrap;
  
  align-items: center;
  padding: 0px 5%;
`

const UserCard = styled.div`
  background-color: #e3dac9;
  border-radius: 5px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  padding: 10px;
  margin-top: 20px;
`
  const UserPic = styled.div`
    width: 100px;
  `
  const Picture = styled.img`
    width: 100px;
    border-radius: 50px;
  `
  const UserInfo = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  
`
  const Title = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    font-family: Roboto;
    font-weight: 600;
  `
  const Subtitle = styled.div`
    margin-top: 10px;
    font-family: Nunito;
    font-size: 12px;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `

  const CloseSession = styled.div`
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 14px;
    background-color: black;
    color: white;
    text-align: center;
    width: 150px;
    border-radius: 5px;
    align-self: center;
    margin-top: 20px;
    cursor: pointer;
    padding: 5px 0px;
  `


const TitleView = styled.div`
  margin-top: 50px;
  align-self: flex-start;
  font-family: Alegreya Sans, sans-serif;
  font-weight: 600;
  font-size: 24px;
`

const Button = styled.button`
  border: none;
  font-family: Alegreya Sanas, sans-serif;
  font-weight: 600;
  font-size: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 70px;
  background-color: #2cccc4;
  color: white;
  cursor: pointer;
  @media(max-width: 950px) {
    margin-top: 50px;
  }
`