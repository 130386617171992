import React, { useState, useLayoutEffect, useEffect } from 'react'
import styled from 'styled-components'
import { TrootState, useAppSelector } from '../../app/store'
import useConfig from '../../hooks/useConfig'
import { BsInstagram, BsFacebook, BsTwitter, BsThreeDotsVertical, BsDashLg } from 'react-icons/bs'
import { SiDiscord } from 'react-icons/si'
import useViewport from '../../hooks/useViewport'

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1220,
}

interface TsocialMedia { theme: string }
export default function SocialMedia(props: TsocialMedia) {
  const { bodyTop, bodyWidth } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [isSD, setIsSD] = useState(false)
  const [show, setShow] = useState<boolean>(false)

  const { showSidebar } = useAppSelector((state: TrootState) => state.appUi)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])


  return(
    <SocialMediaContainer isSidebar={showSidebar ? true : false}>

      { isSD ?
        <Social isSidebar={showSidebar} theme={props.theme} >
          <BsThreeDotsVertical onClick={() => setShow(!show)}/>
        </Social>
          :
        <Social isSidebar={showSidebar} theme={props.theme} >
          { showSidebar ? <Ruler className='REUleezzz'/> : '|' }
        </Social>
      } 

      <SocialLinks theme={props.theme} isSD={isSD} show={show}/>

    </SocialMediaContainer>
  )
}

interface TsocialMediaContainer { isSidebar: boolean }
const SocialMediaContainer = styled.div<TsocialMediaContainer>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  position: relative;
  ${(args) => args.isSidebar && `
    flex-flow: column nowrap;
  `}
`
const Ruler = styled.div`
  border-top: solid 2px #777777;
  max-width: 20px;
  min-width: 20px;
  margin: 20px auto 30px auto;
`




interface TsocialLinks extends TsocialMedia { isSD: boolean, show: boolean }
function SocialLinks(props: TsocialLinks) {
  const config = useConfig()
  const { showSidebar } = useAppSelector((state: TrootState) => state.appUi)

  return(
    <>
      <SocialList 
        isSD={props.isSD}
        showThis={props.show ? true : false}

      >
        {/* <a href={config.app.TWITTER} target="_blank" rel="noreferrer">
          <Social isSidebar={showSidebar} theme={props.theme} > <BsTwitter/> </Social>
        </a> */}



        <a href={'https://www.instagram.com/fitectur2023'} target="_blank" rel="noreferrer">
          <Social isSidebar={showSidebar} theme={props.theme}>
            <BsInstagram/>
          </Social>
        </a>

        <a href={'https://www.facebook.com/FITECTUR'} target="_blank" rel="noreferrer">
          <Social isSidebar={showSidebar} theme={props.theme}>
            <BsFacebook/>
          </Social>
        </a>

        {/* <MetamaskComponent/> */}
      </SocialList>
    </>
  )
}

interface TsocialList { isSD: boolean, showThis: boolean }
const SocialList = styled.div<TsocialList>`
  display: ${(args) => !args.isSD ? 'flex' : args.showThis ? 'flex' : 'none'};
  width: 140px;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
  ${(args) => args.isSD && `
    position: absolute;
    flex-flow: column nowrap;
    height: 120px;
    width: auto;
    padding: 0px 5px;
    bottom: -130px;
    border: solid 1px #88888888;
    border-radius: 5px;
    background-color: white;
  `}
  ${(args) => args.isSD && `
    background-color: #ffffff55;
  `}
`
interface Tsocial { isSidebar: boolean, theme: string  }
const Social = styled.div<Tsocial>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: trade-gothic;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: ${(props) => props.isSidebar ? `#777777` : props.theme === 'ligth' ? `#777777` : `white`};
`

