import React from 'react'
import styled from 'styled-components'
import { TsignInStep } from './SignIn'

import check from '../../../assets/icons/ui/check.png'

interface TresetSucces { changeStep: (step: TsignInStep) => void }
export default function ResetPasswordSuccess(props: TresetSucces) {

  return (
    <ResetPassSuccessContainer>

      <CheckBack>
        <Check src={check}/>
      </CheckBack>

      <Title>Exito!</Title>

      <SubTitle>
        La contraseña ha sido cambiada
      </SubTitle>

      <GoToLoginButton onClick={() => props.changeStep('login')}>
        Iniciar Sesión
      </GoToLoginButton>

    </ResetPassSuccessContainer>
  )
}

const ResetPassSuccessContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 300px;
  margin-top: 50px;
`

const Title = styled.p`
  font-weight: 600;
  font-size:  25px;
  color: #201E1F;
  font-family: Montserrat;
  margin-bottom: 10px;
  text-align: center;
  max-width: 250px;
  margin: 20px auto 0px auto;
`
const SubTitle = styled.p`
  color: #201E1F;
  font-family: Montserrat;
  font-size:  15px;
  font-weight: 600;
  text-align: left;
  max-width: 350px;
  margin: 10px auto;
`

const CheckBack = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #312c38;
  width: 150px;
  height: 150px;
  border-radius: 100px;
`
  const Check = styled.img`
    width: 100px;
  `
const GoToLoginButton = styled.button`
  background-color: #50b2c0;
  width: 70%;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 0px 10px 0px;
  color: white;
  font-family: Montserrat;
  border: none;
  text-decoration: none;
  margin-top: 20px;
  margin-bottom: 20px;
`