import React from 'react'
import styled from 'styled-components'

import HomeVideo from './components/home_video'
import AbstractHome from './components/home_abstract'
import UrusHome from './components/homre_urus'

export default function Home() {

  return(
    <Container>
      <HomeVideo/>
      <AbstractHome/>
      <UrusHome/>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`