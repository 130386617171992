import React, { } from "react"
import styled from 'styled-components'
import { NavLink, useNavigate } from "react-router-dom"

import { TrootState, useAppSelector } from "../../app/store"
import { useSelector, useDispatch } from "react-redux"
import { hiddeSidebar, setActiveView } from "../../app/AppUISlice"

import ViewItem from "./sidebarViewItem";
import SocialMedia from "../header/socialMedia"

import logo_ligth from '../../assets/icons/ui/main-logo.png'
import close_ligth from '../../assets/icons/ui/closeGrey.png'


export default function Sidebar() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { showSidebar, views, activeView } = useSelector(
    (state: TrootState) => state.appUi
  )

  const user = useAppSelector((state: TrootState) => state.user)

  function goHome() {
    const view = views.find((view) => view.path === '/')
    if (view) dispatch(setActiveView(view))
    dispatch(hiddeSidebar())
  }

  return(
    <>
      <SidebarBackground
        showSideBar={showSidebar}
        className="SidebarBackground"
        onClick={ () => dispatch(hiddeSidebar()) }
        style={{ zIndex: 998 }}
      />

      <SidebarMenu
        showSideBar={showSidebar}
        className="SideBar"
        style={{ zIndex: 999 }}
      >
        <TopSide>
          <TitleSidebar className="TitleSidebar">

            <NavLink to='/' onClick={() => goHome()}>
              <Logo src={logo_ligth}/>
            </NavLink>

            <CloseSidebar
              src={ close_ligth}
              onClick={ () => dispatch(hiddeSidebar()) }
            />
          </TitleSidebar>

          <ListViews>
            {
              views.map((view, idx) => (
                <ViewItem
                  key={`sideView${idx}`}
                  view={view}
                  theme={activeView.theme}
                />
              ))
            }
            <SocialMedia theme={activeView.theme}/>
          </ListViews>
        </TopSide>

        { user.data?.username &&
          <BottomSide>
            { user.data?.profile_image &&
              <UserImg
                src={user.data.profile_image}
                onClick={() => {
                  navigate('/profile')
                  dispatch(hiddeSidebar())
                }}
              />
            }
            <p> { user.data.email } </p>
          </BottomSide>
        }

      </SidebarMenu>
    </>
  )
}

interface TSidebarProps { showSideBar: boolean }
const SidebarBackground = styled.div<TSidebarProps>`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: -100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity .2s ease-out;
  ${ props => props.showSideBar && `
      opacity: 1;
      left: 0px;
      right: 0px;
  `};
`
const SidebarMenu = styled.div<TSidebarProps>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: -300px;
  width: 300px;
  background-color: #F0EEDD;
  z-index: 999;
  transition: transform .5s ease-out;
  ${ props => props.showSideBar && `
      transform: translate(-300px, 0);        
  `};
`
const TopSide = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const BottomSide = styled.div`
  display: flex;
  flex-flow: column nowrap;
  font-family: Montserrat;
  font-size: 12px;
  margin-bottom: 10px;
`;

const TitleSidebar = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 20px;
`
  const Logo = styled.img`
    width: 150px;
  `
  const CloseSidebar = styled.img`
    width: 20px;
    position: absolute;
    top: 20px;
    right: 20px;
  `

const ListViews = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-top: 20px;
`

const UserImg = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50px;
  align-self: center;
  
`