import React, {  } from 'react';
import styled from 'styled-components';

import { Tview } from '../../app/types';
import { hiddeSidebar } from '../../app/AppUISlice';

import { NavLink, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { TrootState, useAppSelector } from '../../app/store';


interface thisProps { view: Tview, theme: string }
export default function ViewItem(props: thisProps) {
  const { view, theme } = props
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  const { showSidebar } = useSelector((state: TrootState) => state.appUi)
  const userState = useSelector((state: TrootState) => state.user)
  
  const isMetaMaskVerified = true//metamask.state === 'auth' ? true: false;

  function showThis(caso: string) {
    switch (caso) {
      case 'never':
          return false
      case 'ever':
          return true
      case 'noLogged':
        if (userState.data?.username) return false
        return true
      case 'logged':
        if (userState.data?.username) return true
        return false
      case 'loggedAndMeta':
        if ((userState.data?.username) && (isMetaMaskVerified)) return true
        return false
      default:
        return true
    }
  }

  return(
    <ViewNavItem
      path={pathname}
      isActive={ pathname === view.path }
      isSidebar={showSidebar}
      theme={theme}
      show={showThis(view.show)}
    >
      <NavLink
        to={view.path}
        onClick={() => dispatch(hiddeSidebar())}
      >
        <ButtonLink> { view.title.toUpperCase() } </ButtonLink>
      </NavLink>
    </ViewNavItem>
  )
}

interface TViewNavItem { isActive: boolean, isSidebar: boolean, theme: string, show: boolean, path: string }
const ViewNavItem = styled.div<TViewNavItem>`
  font-family: 'Alegreya Sans', sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  ${(args) => !args.show && `
    display: none;
  `};
  & > a {
    text-decoration: none;
    color: ${(props) => props.isSidebar ? `#777777` : props.theme === 'ligth' ? `#777777` : `white`};

    ${(props) => props.path === '/' && `
      
    `}

    ${(props) => props.isActive && `
      color: black;
      font-weight: 500;
      //text-decoration: underline;
    `}
  }
`

const ButtonLink = styled.div`
  width: calc(min-content + 10px);
  margin: 10px auto 10px auto;
  padding: 0px 10px 0px 10px;
`