import React, { useState, useLayoutEffect } from 'react';
import { TsignUpStep } from './SignUp'
import { signUpRegister } from '../../../services/auth/api'
import { AxiosError } from 'axios';
import Cookies from 'universal-cookie'
import useConfig from '../../../hooks/useConfig'
import styled from 'styled-components'
import { TresponseError } from '../../../services/auth/userApiTypes';

import loadingGif from '../../../assets/gifs/loading.gif'

const cookies = new Cookies();

interface message {
  type: 'info' | 'error' | 'none',
  message: string | undefined,
}

type statusVerification = 'noRequested' | 'sending'
let resended: statusVerification = 'noRequested'

interface Tverification { changeRegisterStep: (step: TsignUpStep) => void }
export default function Verificacion(props: Tverification) {
  const config = useConfig();
  const [ message, setMessage ] = useState<message>({ type: 'none', message: '' })
  const [ resendCodeState, setResendCodeState ] = useState<statusVerification>('noRequested')
  const [ enableContinue, setEnableContinue ] = useState<boolean>(false)
  const [ loading, setLoading ] = useState<boolean>(false)

  function changedInput(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.name === 'codeSignUp') {
      if (event.target.value !== '') setEnableContinue(true)
      else setEnableContinue(false)
    }
  }

  function resendCode() {
    if (resended === 'noRequested') {
      resended = 'sending'
      setResendCodeState('sending')
      setLoading(true)

      const payload = {
        type: "resendcode",
        username: cookies.get(config.app.COOKIE_SIGNUP),
      }

      signUpRegister(payload)
      .then(resp => {
        console.log('Resend Code: ', resp)
        setMessage({type: 'info', message: `Codigo reenviado al email: ${resp.data.CodeDeliveryDetails.Destination}` })
        setLoading(false)
        resended = 'noRequested'
        setResendCodeState('noRequested')
      })
      .catch(err => {
        resended = 'noRequested'
        setResendCodeState('noRequested')
        setLoading(false)

        let error: AxiosError<TresponseError> = err
        setLoading(false)
        setMessage({ type: 'error', message: error.response?.data.message })
        console.log('Resend Code Error: ', err)
      })
    }
  }

  function verification(event: React.SyntheticEvent) {
    event.preventDefault();
    setLoading(true)

    const target = event.target as typeof event.target & {
      codeSignUp: { value: string },
    };

    const payload = {
      type: "verification", 
      username: cookies.get(config.app.COOKIE_SIGNUP),
      verificationCode: target.codeSignUp.value
    }

    signUpRegister(payload)
    .then(resp => {
      setLoading(false)
      console.log('SignUp Code: ', resp)
      cookies.remove(config.app.COOKIE_SIGNUP)
      props.changeRegisterStep('signUpSucces')
    })
    .catch(err => {
      setLoading(false)
      let error: AxiosError<TresponseError> = err
      setMessage({ type: 'error', message: error.response?.data.message })
      console.log('SignUp Verification Error: ', err)
    })
  };

  function goBack() {
    setLoading(true)
    const payload = {
      type: "cancelSignUp",
      username: cookies.get(config.app.COOKIE_SIGNUP),
    };

    signUpRegister(payload)
    .then(resp => {
      console.log('SignUp Verification: ', resp)
      setLoading(false)
      cookies.remove(config.app.COOKIE_SIGNUP)
      props.changeRegisterStep('signup')
    })
    .catch(err => {
      setLoading(false)
      let error: AxiosError<TresponseError> = err
      setLoading(false)
      setMessage({ type: 'error', message: error.response?.data.message })
      console.log('SignUp Verification Error: ', err)
    })
  }

  return (
    <FormContainer>

      <Title>Verificación</Title>

      <SubTitle>
        Se ha enviado un codigo a su correo
      </SubTitle>

      <Form onSubmit={verification}>

        <Input
          type="number"
          placeholder="Codigo"
          name="codeSignUp"
          onChange={changedInput}
        />

        <ResponseMessage type={message.type}>
          { message.message }
        </ResponseMessage>

        <ContinueButton
          type="submit"
          ready={enableContinue}
          disabled={!enableContinue || loading ? true : false}
        >
          { loading ? '' : 'Continuar' }
          <LoadingGif isLoading={loading} src={loadingGif}/>
        </ContinueButton>

      </Form>

      <BottomNav>

        <GoBack onClick={ ()=>{ goBack() }}>
          Volver
        </GoBack>

        <ResendCode codeResended={resendCodeState === 'noRequested'}
          onClick={()=> resendCode()}
        >
          { resendCodeState === 'noRequested' ? 'Resend Code' : 'Code Sent' }
        </ResendCode>

      </BottomNav>

    </FormContainer>
  );

}

const FormContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 300px;
`

const Title = styled.p`
  font-weight: 500;
  font-size:  25px;
  color: #201E1F;
  font-family: Roboto;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 10px;
  text-align: center;
  max-width: 250px;
  margin: 20px auto;
`
const SubTitle = styled.p`
  color: #201E1F;
  font-family: Nunito;
  font-size:  15px;
  font-weight: 600;
  text-align: left;
  max-width: 350px;
  margin: 10px auto;
`
const Form = styled.form`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`
const Input = styled.input`
  width: 90%;
  border: none;
  border-radius: 5px;
  border: 1px solid #f7efe7;
  font-size: 13px;
  line-height: 30px;
  margin: 15px auto 0px auto;
  padding: 5px 5px 5px 15px;
  &:focus {
    outline: 1px solid black;
  }
`

interface TresponseMessage { type: string }
const ResponseMessage = styled.p<TresponseMessage>`
  opacity: ${(args) => args.type !== 'none' ? 1 : 0 };
  height: 30px;
  align-self: flex-end;
  font-weight: 500;
  color: ${(args) => args.type === 'error' ? '#E73C37' : '#312C38' };
  font-size: 12px;
  margin-top: 5px;
  text-align: right;
`

interface TcontinueButton { ready: boolean }
const ContinueButton = styled.button<TcontinueButton>`
  width: 100%;
  background-color: #50b2c055;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 0px 10px 0px;
  color: white;
  border: none;
  text-decoration: none;
  ${(args) => args.ready && `
    background-color: #50b2c0;
  `}
`
  interface TloadingGif { isLoading: boolean }
  const LoadingGif = styled.img<TloadingGif>`
    width: 23px;
    display: none;
    ${(args) => args.isLoading && `
      display: initial;
    `}
  `


const BottomNav = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  font-family: Nunito;
`
  const GoBack = styled.div`
    align-self: flex-start;
    font-size: 14px;
    font-weight: 600;
    margin-top: 20px;
    color: #50b2c0;
    cursor: pointer;
  `
  interface TresendCode { codeResended: boolean }
  const ResendCode = styled.div<TresendCode>`
    font-size: 14px;
    font-weight: 700;
    margin-top: 20px;
    cursor: pointer;
    text-decoration: none !important;
    color: #312c38;
    ${(args) => args.codeResended && `
      text-decoration: none;
    `}
  `