import React from 'react'
import styled from 'styled-components'

import { products } from '../../assets/mocks/products'
import CraftCard from '../../components/craft_card/craft_card'


export default function Marketplace() {

  return(
    <Container>
      <Title> Todos los productos </Title>

      <CraftList>
        { products.map((item) => (
           <CraftCard item={item}/>
        ))}
      </CraftList>

    </Container>
  )
}

const Container = styled.div`
  padding-top: 80px;
`

const Title = styled.p`
  font-family: Alegreya Sans, sans-serif;
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  padding-left: 20px;
`

const CraftList = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  justify-items: center;
  width: calc(100% - 12px);
  margin: 0px auto 0px auto;
  padding: 0px 6px 0px 6px;
  @media(min-width: 950px) {
    grid-template-columns: 25% 25% 25%;
  }
`