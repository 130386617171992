import React, { useLayoutEffect, useState, useEffect } from 'react';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { setActiveView } from './AppUISlice';
import styled from 'styled-components';

import './App.css';

import Header from '../components/header/header';
import Sidebar from '../components/sidebar/sidebar';
import useViewport from '../hooks/useViewport';
import { TrootState, useAppDispatch, useAppSelector } from './store';
import { fetchUserData } from '../services/auth/userSlice';
import Cookies from 'universal-cookie'
import useConfig from '../hooks/useConfig';

const cookies = new Cookies()

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}


function App() {
  const config = useConfig()
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  
  const { bodyWidth, bodyTop } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [, setIsSD] = useState(false)

  const appUi = useAppSelector((state: TrootState) => state.appUi)

  
  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  useEffect(() => {
    if (cookies.get(config.app.COOKIE_USER)) dispatch(fetchUserData())

    
  }, [])

  useEffect(() => {
    const loadedView = appUi.views.find((view) => view.path === pathname)

    if (loadedView) {
      dispatch(setActiveView(loadedView))
      window.scrollTo(0, 0)
    } else {
      const notFound = appUi.views.find((view) => view.path === '/*')
      if (notFound) dispatch(setActiveView(notFound))
    }
  }, [pathname, appUi.views])

  return (
    <AppContainet className="App">

      <Header/>
      { isMobile && <Sidebar/>  }
      <Outlet/>
    </AppContainet>
  );
}

export default App;


const AppContainet = styled.div`
  text-align: center;
  overflow-x: hidden;
  display: flex;
  flex-flow: column nowrap;
  
  
  min-width: 100%;
  align-items: center;
  justify-content: center;
`