import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'


interface Tprops {
  item: {
    id: string,
    title: string,
    mainImage: string,
    images: string[],
    comunidad: string,
    precio: number,
  } 
}
export default function CraftCard(props: Tprops) {
  const { item } = props
  const navigate = useNavigate()

  return(
    <Container
      onClick={() => {
        navigate(`/craft/${item.id}`)
      }}
    >

      <CraftImage src={ item.mainImage } />

      <Title>
        { item.title }
      </Title>

      <Comunidad>
        { item.comunidad }
      </Comunidad>

      <Precio>
        { item.precio } $us
      </Precio>

    </Container>
  )
}

const Container = styled.div`
  width: calc(100% - 12px);
  margin: 5px 6px;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    box-shadow: 2px 2px 2px gray;
  }

`

const CraftImage = styled.img`
  width: 100%;
  
  object-fit: cover;
  border-radius: 5px;  
  @media(min-width: 950px) {
    min-height: calc(100% - 78px);
  }
`

const Title = styled.p`
  text-align: left;
  font-family: Nunito, sans-serif;
  font-size: 13px;
  font-weight: 600;
  margin-top: 2px;
  margin: 5px 0px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 5px);
`

const Comunidad = styled.p`
  font-family: Nunito, sans-serif;
  font-size: 10px;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
  margin: 5px 0px;
`

const Precio = styled.p`
  font-family: Nunito, sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-align: right;
  padding-right: 10px;
  margin: 5px 0px;
`