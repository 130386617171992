import React, { useLayoutEffect, useState } from 'react'
import useViewport from '../../../hooks/useViewport';
import styled from "styled-components"
import ReactPlayer from 'react-player'
import { Link } from "react-router-dom";
import useConfig from '../../../hooks/useConfig';

//import homeBackground from "../../assets/images/home/homeBackground.jpg";

const globalConfig = {
  maxHDWidth: 1340,
  maxSDWidth: 1100,
  mobileThreshold: 950,
  SDthreshold: 1340,
}

export default function HomeVideo () {
  const { app } = useConfig()
  const { bodyTop, bodyWidth } = useViewport()
  const [isMobile, setIsMobile] = useState(true);
  const [, setIsSD] = useState(false);
  

  //const loadedBackground = useProgressiveImage(homeBackground)

  useLayoutEffect(() => {
    if (bodyWidth < globalConfig.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > globalConfig.mobileThreshold) && (bodyWidth < globalConfig.maxHDWidth)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  return (
    <HomeVideCointainer>
      <Video>
        <StyledPlayer
          url={
            isMobile ? 
            `https://s3.sa-east-1.amazonaws.com/mibarrio.static.files/pluriapp/videos/main_mob.mp4`
            :
            `https://s3.sa-east-1.amazonaws.com/mibarrio.static.files/pluriapp/videos/main_desktop.mp4`
          }

          muted={true} loop={true} controls={false} playing={true}
          width={'100%'} height={'100%'}
          config={{
            file: {
              attributes: {
                playsInline: true,
              },
            }, 
          }}
          playsinline={true}
        />
        
      </Video>

      <Content>
        <BodyContent>

          <Title isMobile={isMobile}>
            Un pedacito de Bolivia en cada creación
          </Title>

          <SubTitle isMobile={isMobile}>              
            {`Explora creaciones únicas de mujeres indígenas, llenas de tradición y color. Cada compra apoya comunidades locales. \n¡Lleva contigo un pedacito de cultura!`}
          </SubTitle>

        </BodyContent>
      </Content>

    </HomeVideCointainer>
  );
}

const StyledPlayer = styled(ReactPlayer)`
  min-width: 100%;
  max-width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  object-fit: fill;
`

const HomeVideCointainer = styled.div`
  display: flex;
  background-color: #2cccc4;
  min-height: 100vh;
  /* background-color: black; */
`
const Video = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: fill;
  
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
`
const Content = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 0px; bottom: 0px;
  left: 0px; right: 0px;
  color: white;
  background: linear-gradient(to bottom left, #000000ff 0%, #00000011 31%, #00000000 100%);

  @media(max-width: 950px) {
    justify-content: center;
  }
`
const BodyContent = styled.div`
  display: flex;
  flex-flow: column;
  align-self: center;
  gap: 30px;
  background-color: #000000bb;
  padding: 30px 10px 30px 35px;
  border-radius: 10px;
  width: 400px;
  position: relative;
  margin-left: 60px;

  @media(max-width: 950px) {
    padding: 20px 10px 20px 10px;
    gap: 20px;
    align-self: flex-end;
    width: 73%;
    border-radius: 5px 5px 0px 0px;
    background-color: #00000088;
    margin-left: auto;
    margin-right: auto;
  }
`

  interface TextProps { isMobile: boolean }
  const Title = styled.div<TextProps>`
    color: #2cccc4 ;
    font-family: 'Alegreya Sans', sans-serif;
    font-size: 50px;
    font-weight: 600;
    line-height: 50px;
    text-align: left;
    

    ${(args) => args.isMobile && `
      padding: 0px 0px 0px 0px;
      line-height: 25px;
      font-size: 30px;
      font-weight: 600;
      font-style: italic;
      text-align: center;
    `}
  `
interface TbuttonProps { isMobile: boolean }
const SubTitle = styled.div<TbuttonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  text-align: left;
  
  position: relative;
  max-width: 470px;
  

  font-family: Nunito;
  font-weight: 700;
  font-size: 18px;
  color: white;
  white-space: pre-line;
  
  ${(args) => args.isMobile && `
    max-width: 290px;
    font-size: 16px;
    max-width: 270px;
    margin: auto auto auto auto;
    text-align: center;
  `}
`