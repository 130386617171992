import React from 'react'
import styled from 'styled-components'

import urusMain from '../../../assets/images/urus/urus_main.jpg'
import urus01 from '../../../assets/images/urus/urus_01.jpg'
import urus02 from '../../../assets/images/urus/urus_02.jpg'

export default function AbstractHome() {

  return(
    <Container>

      <Side>
        <Title>
          Conecta con la Cultura Boliviana a través de Nuestras Artesanías Únicas
        </Title>
        <Desc>
          Te damos la bienvenida a un viaje a través de la rica herencia artesanal de Bolivia. Descubre piezas auténticas, elaboradas con amor y habilidad por mujeres indígenas. Cada adquisición es una forma de apoyar y celebrar la creatividad y tradición arraigada en cada obra. Lleva contigo un tesoro boliviano y sé parte de esta historia de empoderamiento y cultura.
        </Desc>
      </Side>

      <Side>
        <PhotoWrap>
          <Img src={urus02} />
        </PhotoWrap>

      </Side>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  background-color: #F0EEDD;
  background: 
    linear-gradient(to left, #00000055, transparent 15%),
    linear-gradient(to right, #00000055, transparent 15%);
  display: flex;
  gap: 20px;
  justify-content: center;
  padding: 130px 0px;
  
  @media (max-width: 950px) {
    flex-flow: column nowrap;
    padding: 50px 0px;
    background: none;
  }
`

const Side = styled.div`
  display: flex;
  gap: 50px;
  flex-flow: column nowrap;
  max-width: 500px;
  min-width: 500px;
  justify-content: center;
  
  margin: 0px 0px 0px 0px;
  

  @media (max-width: 950px) {
    max-width: 300px;
    min-width: 300px;
    margin: 0px auto 0px auto;
  }
  
`

const Title = styled.p`
  text-align: left;
  font-family: Alegreya Sans, sans-serif;
  font-size: 24px;
  font-weight: 600;
  max-width: 350px;

  margin: 0px auto 0px auto;
  @media (max-width: 950px) {
    text-align: center;
  }
`

const Desc = styled.p`
  text-align: justify;
  font-family: Nunito;
  font-size: 20px;
  
  max-width: 350px;
  margin: 0px auto 0px auto;
  
  @media (max-width: 950px) {
    text-align: center;
  }
`

const PhotoWrap = styled.div`
  object-fit: cover;
  
  
`

const Img = styled.img`
  height: 600px;
  width: 450px;
  object-fit: cover;
  

  margin: -4px 4px 4px -4px;
	 
	
	padding: 4px;
	background-color: #fcfcfc;
	border-left: 1px solid #ccc;
	border-top: 1px solid #ccc;
	border-right: 1px solid #888;
	border-bottom: 1px solid #888;

  box-shadow: 10px 5px 5px gray;

  @media (max-width: 950px) {
    height: 400px;
    width: 300px;
  }
`