import React, { useState, useLayoutEffect, useEffect } from 'react'
import styled from 'styled-components'
import { TrootState, useAppSelector } from '../../app/store'
import { useSelector, useDispatch } from 'react-redux'
import { displaySidebar } from '../../app/AppUISlice'
import useViewport from '../../hooks/useViewport'

import ViewItem from '../../components/sidebar/sidebarViewItem'
import SocialMedia from './socialMedia'

import menuIconLigth from '../../assets/icons/ui/menu_ligth.png';
import menuIconDark from '../../assets/icons/ui/menu_dark.png';
import logo from '../../assets/icons/ui/main-logo.png'

import { NavLink } from 'react-router-dom'

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1380,
}

export default function Header() {
  const dispatch = useDispatch()
  const { bodyTop, bodyWidth } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [isSD, setIsSD] = useState(false)

  const { views, activeView } = useAppSelector(
    (state: TrootState) => state.appUi
  )

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  useEffect(() => {
    console.log(activeView)
  }, [activeView])


  return(
    <HeaderElement style={{zIndex: 997}}>

      <LeftSide>
        <NavLink to='/'>
          <Logo
            src={ activeView.theme === 'ligth' ? logo : logo}
            isMobile={isMobile}
            isSD={isSD}
          />
        </NavLink>
      </LeftSide>

      {
        !isMobile &&
        <RightSide>

          <ListViews className='ListViews'>
            {
              views.map((view, idx) => (
                <ViewItem
                  key={`sideView${idx}`}
                  theme={activeView.theme}
                  view={view}
                />
              ))
            }
            <SocialMedia theme={activeView.theme}/>
          </ListViews>
          

        </RightSide>
      }

      {
        isMobile && <RightSideMobile
          onClick={() => { dispatch(displaySidebar()) }}
          src={ activeView.theme === 'ligth' ? menuIconLigth : menuIconDark} 
        />
      }

    </HeaderElement>
  );
};

const HeaderElement = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 120px;
  @media (max-width: 950px) {
    height: 80px;
  }
`;

interface Tmobile { isMobile: boolean, isSD: boolean }
const LeftSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
  const Logo = styled.img<Tmobile>`
    width: ${(props) => props.isMobile ? `40px` : props.isSD ? '60px' :`60px` };
    margin-left: ${(props) => props.isMobile ? `30px` : `60px` };
  `

const RightSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
`;

  const ListViews = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
  `

const RightSideMobile = styled.img`
  margin-right: 30px;
  width: 55px;
`;