import { createBrowserRouter } from 'react-router-dom'
import App from './App'

import Home from '../views/home/home'
import About from '../views/about/about'
import Marketplace from '../views/marketplace/marketplace'
import Craft from '../views/craft/craft'
import AuthView from '../views/auth/auth'


export const router = createBrowserRouter([
  {
    element: <App/>,
    id: "root",
    children: [
      {
        index: true,
        element: <Home/>,
        id:'home',
        handle: { scrollMode: 'pathname' },
      },
      {
        index: true,
        path: '/about',
        element: <About/>,
        id:'about',
      },
      {
        index: true,
        path: '/marketplace',
        element: <Marketplace/>,
        id:'marketplace',
      },
      {
        path: "/craft/:craft",
        element: <Craft/>
      },
      {
        path: "/login",
        element: <AuthView/>
      },
      {
        path: "/profile",
        element: <AuthView/>
      },
    ]
  },
  
])

export default router