import carft_01_main from '../images/crafts/craft_01_main.jpg'
import carft_02_main from '../images/crafts/craft_02_main.jpg'
import carft_03_main from '../images/crafts/craft_03_main.jpg'

export const products = [
  {
    id: 'balsa_tejida_totora',
    title: 'Balsa tejida totora',
    mainImage: carft_01_main,
    images: [carft_01_main],
    comunidad: 'nacion urus',
    precio: 50,
    desc: 'La balsa de totora es una embarcación ancestral utilizada por los pueblos originarios andinos, especialmente en regiones como el lago Titicaca. Está hecha a mano con tallos de totora, una planta acuática nativa de la región. Su diseño único y flotabilidad hacen de la balsa una herramienta esencial para la pesca y el transporte en zonas lacustres. Aunque sencilla en su construcción, la balsa de totora representa una profunda conexión con la naturaleza y la tradición de estos pueblos.'
  },
  {
    id: 'cesto_tejido_zambullidor',
    title: 'Cesto tejido zambullidor (Keñola)',
    mainImage: carft_02_main,
    images: [carft_02_main],
    comunidad: 'nacion urus',
    precio: 25,
    desc: 'El zambullidor del Titicaca (Podiceps occipitalis) es una especie de ave acuática endémica del lago Titicaca, situado en la región andina de Sudamérica, entre Perú y Bolivia. Este ave de plumaje oscuro y cuello elegante es conocida por su habilidad para sumergirse en busca de alimento, como pequeños peces y crustáceos. Su adaptación al entorno acuático del lago Titicaca lo convierte en un indicador importante de la salud de este ecosistema. Es un símbolo de la rica biodiversidad y la belleza natural que caracteriza a esta región única en el mundo.'
  },
  {
    id: 'cesto_tejido_porta_joyas',
    title: 'Cesto tejido porta joyas',
    mainImage: carft_03_main,
    images: [carft_03_main],
    comunidad: 'nacion urus',
    precio: 20,
    desc: 'Los cestos tejidos por los pueblos originarios andinos que habitan cerca del lago Titicaca son verdaderas obras de arte funcional. Elaborados con técnicas ancestrales de tejido, utilizan materiales naturales como la totora y fibras vegetales. Estos cestos no solo son prácticos para transportar y almacenar alimentos, sino que también reflejan la rica tradición artesanal de estas comunidades, así como su profunda conexión con la naturaleza y el entorno acuático del lago Titicaca. Cada cesto es una expresión de habilidad y creatividad, y representa un elemento importante de la cultura andina.'
  },
]